<div class="flex flex-row justify-content-center relative py-8 skew-bottom-right bg-blue-dark px-4 md:px-0">
    <div class="container flex-grow-1 flex flex-column flex-wrap align-content-center z-5 justify-content-center gap-5 text-center py-8">
        <span class="text-white text-7xl font-bold">
            {{ 'CLIENT.SECURITY.HEADER.TITLE' | translate }}
        </span>
        <p class="text-white text-xl font-semibold md:w-6 line-height-4" [innerHTML]="'CLIENT.SECURITY.HEADER.DESCRIPTION' | translate"></p>
    </div>
</div>

<div class="flex flex-row justify-content-center relative py-8">
    <div class="container flex-grow-1 flex flex-row flex-wrap py-4 px-4 md:px-0 justify-content-center">
        <div class="md:w-10 flex flex-column lg:flex-row gap-5 justify-content-center">
            <div class="flex flex-column md:w-6 gap-6">
                <div class="flex flex-column gap-5">
                    <span class="font-bold text-blue-darker text-6xl md:text-7xl">
                        {{ 'CLIENT.SECURITY.HERO.TITLE' | translate }}
                    </span>
                </div>
                <p class="font-semibold" [innerHTML]="'CLIENT.SECURITY.HERO.DESCRIPTION' | translate"></p>
            </div>
            <div class="flex w-full md:w-6 h-30rem">
                <div class="circles relative">
                    <div class="flex flex-column gap-4 absolute general-circle">
                        <div class="flex flex-row justify-content-center align-items-center gap-1">
                            <i class="icon-round-star icon-16 bg-blue-darker"></i>
                            <span class="font-bold text-base">
                                {{ 'CLIENT.SECURITY.HERO.BIG_RATING' | translate }}
                            </span>
                        </div>
                        <circle-progress
                            [percent]="93"
                            [radius]="118"
                            [space]="-34"
                            [outerStrokeWidth]="27"
                            [innerStrokeWidth]="40"
                            [outerStrokeColor]="'#2079E2'"
                            [outerStrokeGradientStopColor]="'#134988'"
                            [title]="'9.6'"
                            [titleFontSize]="'42'"
                            titleFontWeight="800"
                        ></circle-progress>
                    </div>

                    <div class="flex flex-column gap-3 justify-content-center align-items-center absolute code-circle">
                        <span class="font-medium text-base text-grey-dark-hover text-center">
                            {{ 'CLIENT.SECURITY.HERO.SMALL_RATING' | translate }}
                        </span>
                        <circle-progress
                            [percent]="93"
                            [radius]="52"
                            [space]="-15"
                            [outerStrokeWidth]="12"
                            [innerStrokeWidth]="18"
                            [outerStrokeColor]="'#30C5AB'"
                            [outerStrokeGradientStopColor]="'#30C5AB'"
                            [title]="'9.8'"
                            [titleFontSize]="'20'"
                            titleFontWeight="800"
                        ></circle-progress>
                    </div>

                    <div class="flex flex-column gap-3 justify-content-center align-items-center absolute activity-circle">
                        <span class="font-medium text-base text-grey-dark-hover text-center">
                            {{ 'CLIENT.SECURITY.HERO.MEDIUM_RATING' | translate }}
                        </span>
                        <circle-progress
                            [percent]="93"
                            [radius]="67"
                            [space]="-19"
                            [outerStrokeWidth]="15"
                            [innerStrokeWidth]="23"
                            [outerStrokeColor]="'#5E9DE8'"
                            [outerStrokeGradientStopColor]="'#5E9DE8'"
                            [title]="'9.7'"
                            [titleFontSize]="'26'"
                            titleFontWeight="800"
                        ></circle-progress>
                    </div>

                    <div class="flex flex-column gap-3 justify-content-center align-items-center absolute vulnerabilities-circle">
                        <span class="font-medium text-base text-grey-dark-hover text-center">
                            {{ 'CLIENT.SECURITY.HERO.SMALLEST_RATING' | translate }}
                        </span>
                        <circle-progress
                            [percent]="93"
                            [radius]="35"
                            [space]="-13"
                            [outerStrokeWidth]="10"
                            [innerStrokeWidth]="16"
                            [outerStrokeColor]="'#FF6987'"
                            [outerStrokeGradientStopColor]="'#FF6987'"
                            [title]="'9.4'"
                            [titleFontSize]="'20'"
                            titleFontWeight="800"
                        ></circle-progress>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-center relative py-6">
    <div class="container w-full flex-grow-1 flex flex-row flex-wrap py-4 justify-content-center">
        <div class="w-full md:w-10 flex flex-column gap-7 justify-content-center">
            <span class="text-6xl font-bold px-4 md:px-0">
                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.TITLE' | translate }}
            </span>
            <div class="flex flex-row gap-4 overflow-auto flex-nowrap w-full px-4 md:px-0 slider">
                <!-- style="flex-basis: 30%;" -->
                <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                    <img src="assets/shield-check.svg" width="90" alt="">
                    <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SECURITY.DIFFERENTIATOR.FIRST_CARD.TITLE' | translate }}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.DIFFERENTIATOR.FIRST_CARD.DESCRIPTION' | translate"></p>
                </div>
                <!-- style="flex-basis: 40%;" -->
                <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                    <img src="assets/comment-info.svg" width="90" alt="" class="align-self-center">
                    <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SECURITY.DIFFERENTIATOR.SECOND_CARD.TITLE' | translate }}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.DIFFERENTIATOR.SECOND_CARD.DESCRIPTION' | translate"></p>
                </div>
                <!-- style="flex-basis: 30%;" -->
                <div class="flex flex-none md:flex-auto flex-column bg-blue-light p-5 border-grey-normal border-1 border-round-2xl align-items-center w-full md:w-auto slide">
                    <img src="assets/hands-usd.svg" width="90" alt="">
                    <p class="font-bold text-3xl text-blue-darker text-center">{{ 'CLIENT.SECURITY.DIFFERENTIATOR.THIRD_CARD.TITLE' | translate }}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.DIFFERENTIATOR.THIRD_CARD.DESCRIPTION' | translate"></p>
                </div>
            </div>
            <!-- <div class="flex flex-row gap-4">
                <div class="flex flex-column bg-blue-light px-5 py-6 w-5 border-grey-normal border-1 border-round-2xl gap-7">
                    <div class="flex flex-column gap-6">
                        <i class="icon-90 icon-certificate bg-blue-normal align-self-center"></i>
                        <div class="gap-5 flex flex-column">
                            <span class="font-bold text-3xl text-blue-darker text-center">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.FIRST_CARD.TITLE' | translate }}
                            </span>
                            <span class="text-base font-normal text-grey-dark-hover">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.FIRST_CARD.DESCRIPTION' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column bg-blue-light px-5 py-6 w-7 border-grey-normal border-1 border-round-2xl gap-7">
                    <div class="flex flex-column gap-6">
                        <i class="icon-90 icon-click bg-blue-normal align-self-center"></i>
                        <div class="gap-5 flex flex-column">
                            <span class="font-bold text-3xl text-blue-darker text-center">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.SECOND_CARD.TITLE' | translate }}
                            </span>
                            <span class="text-base font-normal text-grey-dark-hover">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.SECOND_CARD.DESCRIPTION' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column bg-blue-light px-5 py-6 w-5 border-grey-normal border-1 border-round-2xl gap-7">
                    <div class="flex flex-column gap-6">
                        <i class="icon-90 icon-chart bg-blue-normal align-self-center"></i>
                        <div class="gap-5 flex flex-column">
                            <span class="font-bold text-3xl text-blue-darker text-center">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.THIRD_CARD.TITLE' | translate }}
                            </span>
                            <span class="text-base font-normal text-grey-dark-hover">
                                {{ 'CLIENT.SECURITY.DIFFERENTIATOR.THIRD_CARD.DESCRIPTION' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>

<div class="flex flex-row justify-content-center relative py-4 md:py-8 skew-top-right bg-technic mb-8">
    <div class="container flex-grow-1 flex flex-column flex-wrap align-content-center z-5 justify-content-center gap-5 text-center pb-4 md:py-8">
        <p class="text-white text-7xl font-bold m-0" [innerHTML]="'CLIENT.SECURITY.INTERLUDE.TITLE' | translate"></p>
        <p class="text-white text-xl font-semibold md:w-6 m-0 px-4 md:px-0" [innerHTML]="'CLIENT.SECURITY.INTERLUDE.DESCRIPTION' | translate"></p>
    </div>
</div>

<div class="flex flex-column align-items-center md:mb-8 md:pb-8 pb-4 mb-4">
    <div class="container flex flex-column w-full align-items-center z-5 px-4 md:px-0">
        <div class="font-bold text-5xl text-blue-darker">
            <img src="assets/flame.png" alt="top products" class="h-full">
            {{ 'CLIENT.SECURITY.TOP_LIST.TITLE' | translate }}
        </div>
        <div class="border-round-4xl border-1 border-blue-light-active bg-blue-light flex flex-column w-full p-4 md:p-6 border-round-lg gap-6 mt-6 md:mt-0">
            <div class="flex flex-column md:flex-row flex-wrap row-gap-4 justify-content-between">
                <a routerLink="/products/{{product.id}}" class="flex flex-grow-1 w-full flex-row gap-2 product-card no-underline justify-content-between" *ngFor="let product of securityPageData.products">
                    <div class="flex flex-column justify-content-evenly overflow-hidden">
                        <p class="m-0 text-xl font-semibold text-blue-darker overflow-hidden text-overflow-ellipsis">{{ product.name | translate }}</p>
                        <p class="m-0 text-blue-darker webkit-box line-clamp-2 overflow-hidden webkit-box-vertical">{{ product.summary | translate }}</p>
                    </div>
                    <app-score-circle
                      [score] = "product.rating"
                      [trend] = "product.lastTrend"
                      helpText="CLIENT.SECURITY.TOP_LIST.SCORE.HELP_TEXT"
                      [radius]="45"
                      [outerStrokeColor]="'#2079E2'"
                      [outerStrokeGradientStopColor]="'#134988'"
                    >
                    </app-score-circle>
                </a>
            </div>
            <div class="flex flex-row justify-content-center w-full">
                <a routerLink="/catalog" class="no-underline font-bold text-blue-light p-3 bg-blue-normal border-round-lg">{{ 'CLIENT.SECURITY.TOP_LIST.BUTTON.GO_TO_CATALOG' | translate }}</a>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-column justify-content-center pt-4 skew-top-right-lg bg-blue-light-hover align-items-center pb-6">
    <!-- px-px-160 -->
    <div class="container w-full flex flex-column md:pt-180px md:px-px-160 px-4">
        <p class="font-bold text-6xl text-blue-darker mb-0">{{ 'CLIENT.SECURITY.BLOG_POSTS.TITLE' | translate }}</p>
        <div class="flex flex-column gap-6">
            <div class="hidden md:flex mt-8 flex-row gap-7">
                <div class="cover">
                  <img src="/assets/security-support.jpg" alt="" class="w-27rem border-round-lg">
                </div>
                <div>
                    <p class="font-bold text-3xl">{{ 'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.TITLE' | translate }}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.DESCRIPTION' | translate"></p>
                </div>
            </div>
            <div class="flex md:hidden flex-column gap-4 mt-6">
                <div class="flex flex-column gap-3">
                    <div>
                        <img src="/assets/security-support.jpg" alt="" class="border-round-lg" style="width: 100%; height: 100%;">
                    </div>
                    <p class="font-bold text-lg m-0">{{ 'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.TITLE' | translate }}</p>
                </div>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.DESCRIPTION' | translate"></p>
            </div>
            <div class="w-full border-top-1 border-blue-light-active"></div>
            <div class="hidden md:flex flex-row gap-7">
                <div>
                    <p class="font-bold text-3xl">{{ 'CLIENT.SECURITY.TRAITS.SECOND_TRAIT.TITLE' | translate}}</p>
                    <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.SECOND.FIRST_TRAIT.DESCRIPTION' | translate"></p>
                </div>
                <div class="cover">
                  <img src="/assets/security-analysis.jpg" alt="" class="w-27rem border-round-lg">
                </div>
            </div>
            <div class="flex md:hidden flex-column gap-4">
                <div class="flex flex-column-reverse gap-3 justify-content-between">
                    <p class="font-bold text-lg m-0">{{ 'CLIENT.SECURITY.TRAITS.SECOND_TRAIT.TITLE' | translate }}</p>
                    <div>
                        <img src="/assets/security-analysis.jpg" alt="" class="border-round-lg" style="width: 100%; height: 100%;">
                    </div>
                </div>
                <p class="text-grey-dark" [innerHTML]="'CLIENT.SECURITY.SECOND.FIRST_TRAIT.DESCRIPTION' | translate"></p>
            </div>
        </div>
    </div>
</div>

<!--
<div class="flex flex-row justify-content-center relative py-8 skew-top-right-lg bg-blue-light-hover">
    <div class="container flex-grow-1 flex flex-column flex-wrap z-5 gap-5 py-8">
        <div class="flex flex-column gap-8">
            <span class="text-6xl font-bold text-blue-darker">
                {{ 'CLIENT.SECURITY.BLOG_POSTS.TITLE' | translate }}
            </span>
            <div class="flex flex-column gap-6">
                <div class="flex flex-row gap-5 w-10">
                    <div class="cover">
                      <img src="/assets/security-support.jpg" alt="" class="w-27rem border-round-lg">
                    </div>
                    <div class="flex flex-column gap-5 justify-content-center">
                        <span class="text-blue-darker text-3xl font-bold">{{ 'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.TITLE' | translate }}</span>
                        <span class="font-normal text-base text-grey-dark-hover">
                            {{ 'CLIENT.SECURITY.TRAITS.FIRST_TRAIT.DESCRIPTION' | translate }}
                        </span>
                    </div>
                </div>
                <hr class="m-0 border-1 border-blue-light-active z-5" />
                <div class="flex flex-row-reverse gap-6 justify-content-end w-10 align-self-end">
                    <div class="cover">
                        <img src="/assets/security-analysis.jpg" alt="" class="w-27rem border-round-lg">
                    </div>
                    <div class="flex flex-column gap-5 justify-content-center">
                        <span class="text-blue-darker text-3xl font-bold">{{ 'CLIENT.SECURITY.TRAITS.SECOND_TRAIT.TITLE' | translate }}</span>
                        <span class="font-normal text-base text-grey-dark-hover">
                            {{ 'CLIENT.SECURITY.SECOND.FIRST_TRAIT.DESCRIPTION' | translate }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="flex flex-row justify-content-center relative py-5">
    <div class="container flex-grow-1 flex flex-column flex-wrap z-5 gap-6 py-8">
        <div class="flex flex-row justify-content-center">
            <span class="text-6xl font-bold text-blue-darker">
                {{ 'CLIENT.SECURITY.RECOMMENDED_POSTS.TITLE' | translate }}
            </span>
        </div>
        <div class="flex flex-row flex-grow-1 gap-3">
            <div class="flex flex-column gap-4">
                <img src="/assets/about-us-1.png" alt="" class="align-self-center max-w-full border-round-lg">
                <div class="flex flex-column gap-2">
                    <span class="font-semibold text-xl text-blue-darker">
                        Składowe oceny SourceMation
                    </span>
                    <span class="text-grey-dark-hover">
                        18.06.2023
                    </span>
                </div>
                <span class="text-grey-dark-hover">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
                </span>
                <div class="flex flex-row">
                    <a href="#" class="flex flex-row gap-2 no-underline align-items-center text-blue-normal py-3 px-4 border-1 border-blue-normal border-round-lg">
                        <span>
                            {{ 'CLIENT.SECURITY.RECOMMENDED_POSTS.BUTTON.GO_TO_POST' | translate }}
                        </span>
                        <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
                    </a>
                </div>
            </div>
            <div class="flex flex-column gap-4">
                <img src="/assets/about-us-1.png" alt="" class="align-self-center max-w-full border-round-lg">
                <div class="flex flex-column gap-2">
                    <span class="font-semibold text-xl text-blue-darker">
                        Składowe oceny SourceMation
                    </span>
                    <span class="text-grey-dark-hover">
                        18.06.2023
                    </span>
                </div>
                <span class="text-grey-dark-hover">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
                </span>
                <div class="flex flex-row">
                    <a href="#" class="flex flex-row gap-2 no-underline align-items-center text-blue-normal py-3 px-4 border-1 border-blue-normal border-round-lg">
                        <span>
                            {{ 'CLIENT.SECURITY.RECOMMENDED_POSTS.BUTTON.GO_TO_POST' | translate }}
                        </span>
                        <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
                    </a>
                </div>
            </div>
            <div class="flex flex-column gap-4">
                <img src="/assets/about-us-1.png" alt="" class="align-self-center max-w-full border-round-lg">
                <div class="flex flex-column gap-2">
                    <span class="font-semibold text-xl text-blue-darker">
                        Składowe oceny SourceMation
                    </span>
                    <span class="text-grey-dark-hover">
                        18.06.2023
                    </span>
                </div>
                <span class="text-grey-dark-hover">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet nunc quis nibh lobortis pretium ...
                </span>
                <div class="flex flex-row">
                    <a href="#" class="flex flex-row gap-2 no-underline align-items-center text-blue-normal py-3 px-4 border-1 border-blue-normal border-round-lg">
                        <span>
                            {{ 'CLIENT.SECURITY.RECOMMENDED_POSTS.BUTTON.GO_TO_POST' | translate }}
                        </span>
                        <i class="icon-16 icon-chevron-right bg-blue-normal"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex flex-row flex-grow-1 gap-3 justify-content-center">
            <div class="flex flex-row gap-2">
                <a href="" class="flex py-12-px px-3 border-1 border-grey-normal border-round-lg justify-content-center align-items-center w-3rem max-w-3rem">
                    <i class="icon-chevron-left icon-16 bg-grey-dark-active"></i>
                </a>
                <a href="" class="flex py-12-px px-3 border-1 border-grey-normal border-round-lg justify-content-center align-items-center no-underline w-3rem max-w-3rem">
                    <span class="text-grey-dark-active font-bold">
                        1
                    </span>
                </a>
                <a href="" class="flex py-12-px px-3 border-1 border-grey-normal border-round-lg justify-content-center align-items-center no-underline w-3rem max-w-3rem">
                    <span class="text-grey-dark-active font-bold">
                        ...
                    </span>
                </a>
                <a href="" class="flex py-12-px px-3 border-1 border-grey-normal border-round-lg justify-content-center align-items-center w-3rem max-w-3rem">
                    <i class="icon-chevron-right icon-16 bg-grey-dark-active"></i>
                </a>
            </div>
        </div>
    </div>
</div> -->
