import { Environment } from "./environment.type";

export const environment: Environment = {
  production: false,
  apiUrl: 'api.preprod.sourcemation.com',
  secure: true,
  githubClientId: 'c33a2f4f442a187b27e7',
  googleClientId: '576813311542-ft81l3thdgk2vrgn11n4a6fikt22jhqe.apps.googleusercontent.com',
  gcpId: ['8a4432a6-ce23-4bcd-b2b4-df283c4bac57'],
  awsId: ['2d12537a-de53-4e54-950e-f3f6fb3262ae'],
  azureId: ['af3a71c9-fac1-45e0-9424-1a97f0594800'],
  dockerId: ['2bb83678-f293-49ea-af92-fc6e8f7582d4'],
  vmwareId: ['ed49d83e-9515-402e-ba1a-ffa9fdbfdd07'],
  kubernetesId: ['60c6f582-d0e2-4f99-a893-f7f7e3791901'],
  alibabaId: ['67fd60a0-5556-482b-b07c-2a53ff7ae71b'],
  rancherId: ['1800dfb9-4f4b-4029-a9ba-44261895120e'],
  promotedCategories: [
    {
      id: 'a4ebbb8e-f8f9-4d74-b80f-a2f57fe8146d',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'Moduł: Bezpieczeństwo',
        EN: 'Module: Security',
      },
    },
    {
      id: '67f7e2ab-0bc3-4ea4-8eff-a89ce98ea415',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'Moduł: Monitoring',
        EN: 'Module: Monitoring',
      },
    },
    {
      id: 'dffa73c7-4588-41a5-979f-a686a6b085f5',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'Moduł: CICD Classic',
        EN: 'Module: CICD Classic',
      },
    },
    {
      id: '5352075b-c105-4819-ba77-44d1d76ce92d',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'Moduł: CICD GitOps',
        EN: 'Module: CICD GitOps',
      },
    },
    {
      id: '54369d2c-7769-42f0-b8bc-62259fa9fca2',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'EZD RP',
        EN: 'EZD RP',
      },
    },
    {
      id: '03db927c-4ff5-465c-951c-f9317d55360f',
      type: 'TAG',
      iconUrl: '',
      name: {
        PL: 'Bazy danych',
        EN: 'Databases',
      },
    },
  ],
  turnstileSiteKey: "0x4AAAAAAAbTO_uID0J5GGuv",
  ezdrpId: ['0055b586-a15e-429f-9f92-616d0a517b1d'],
  ezdrpTagId: ['54369d2c-7769-42f0-b8bc-62259fa9fca2'],
  ezdrpSaasId: ['34a141e5-2243-434c-b257-290899f05940']
}