<div class="flex flex-column justify-content-center align-items-center pt-7 mb-8 bg-blue-dark end-skew-blue-dark z-1">
  <div class="container w-full flex flex-column text-center justify-content-center align-items-center gap-5 px-4 md:px-0" style="padding-bottom: 80px; padding-top: 80px;">
    <span class="font-bold  text-6xl md:text-7xl text-white">{{ 'CLIENT.ABOUT_US.TITLE' | translate }}</span>
    <span class="font-semibold text-xl text-white md:w-6 line-height-4">
      {{ 'CLIENT.ABOUT_US.SUMMARY' | translate }}
    </span>
  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center mb-8">
  <div class="container w-full flex flex-column gap-5 px-4 md:px-0" style="padding-bottom: 80px;">
    <span class="font-semibold text-5xl text-blue-darker">
      {{ 'CLIENT.ABOUT_US.FIRST_SEGMENT.TITLE' | translate }}
    </span>
    <div class="flex flex-column-reverse md:flex-row md:mt-5 gap-6">
      <div class="md:w-6 flex flex-column gap-7 text-base text-grey-dark-hover">
        <span class="font-semibold">
          {{ 'CLIENT.ABOUT_US.FIRST_SEGMENT.DESCRIPTION.FIRST_PARAGRAPH' | translate }}
        </span>
        <span>
          {{ 'CLIENT.ABOUT_US.FIRST_SEGMENT.DESCRIPTION.SECOND_PARAGRAPH' | translate }}
        </span>
        <span>
          {{ 'CLIENT.ABOUT_US.FIRST_SEGMENT.DESCRIPTION.THIRD_PARAGRAPH' | translate }}
        </span>
      </div>
      <div class="md:w-6 flex flex-column">
        <div class="about-us h-25rem our-mission flex-grow-1 w-full"></div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center mb-8 bg-blue-light-hover start-skew-blue-light">
  <div class="container w-full flex flex-column gap-5 px-4 md:px-0" style="padding-bottom: 80px;">
    <span class="font-semibold text-5xl text-blue-darker">
      {{ 'CLIENT.ABOUT_US.SECOND_SEGMENT.TITLE' | translate }}
    </span>
    <div class="flex flex-column-reverse md:flex-row mt-5 gap-6">
      <div class="md:w-6 flex flex-column gap-7 text-base text-grey-dark-hover">
        <span class="font-semibold">
          {{ 'CLIENT.ABOUT_US.SECOND_SEGMENT.DESCRIPTION.FIRST_PARAGRAPH' | translate }}
        </span>
        <span>
          {{ 'CLIENT.ABOUT_US.SECOND_SEGMENT.DESCRIPTION.SECOND_PARAGRAPH' | translate }}
        </span>
        <span>
          {{ 'CLIENT.ABOUT_US.SECOND_SEGMENT.DESCRIPTION.THIRD_PARAGRAPH' | translate }}
        </span>
      </div>
      <div class="md:w-6 flex flex-column">
        <div class="about-us h-25rem flex-grow-1 w-full"></div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-column justify-content-center align-items-center mb-8">
  <div class="container w-full flex flex-column gap-5 px-4 md:px-0" style="padding-bottom: 80px;">
    <span class="font-semibold text-5xl text-blue-darker">
      {{ 'CLIENT.ABOUT_US.CONTACT.TITLE' | translate }}
    </span>
    <span class="text-grey-dark-hover">
      {{ 'CLIENT.ABOUT_US.CONTACT.DESCRIPTION' | translate }}
    </span>
    <div class="flex flex-column md:flex-row mt-5 gap-6 align-items-start">
      <div class="w-full md:w-6 flex flex-column gap-7 text-base text-grey-dark-hover">
        <div class="flex flex-column sm:flex-row gap-4">
          <div class="flex flex-column md:w-6 gap-4">
            <span class="font-semibold text-base text-blue-darker">
              {{ 'CLIENT.ABOUT_US.CONTACT.ADDRESS_DATA' | translate }}
            </span>
            <div class="flex flex-column">
              <span>
                Al. Jerozolimskie 100
              </span>
              <span>
                00-807 Warszawa, Polska
              </span>
              <span>
                NIP: 7010181018
              </span>
              <span>
                KRS: 0000326158
              </span>
            </div>
          </div>
          <div class="flex flex-column md:w-6 gap-4">
            <span class="font-semibold text-base text-blue-darker">
              {{ 'CLIENT.ABOUT_US.CONTACT.CONTACT_DETAILS' | translate }}
            </span>
            <div class="flex flex-column">
              <span>
                tel.: (+48) 22 213 95 71
              </span>
              <span>
                fax.: (+48) 22 213 96 71
              </span>
              <span>
                email: sourcemation@linuxpolska.com
              </span>
            </div>
          </div>
        </div>
        <div class="flex flex-column h-30rem">
          <div class="map-1 flex-grow-1 w-full h-full"></div>
        </div>
      </div>
      <form class="w-full md:w-6 flex flex-column gap-5" [formGroup]="formGroup" (ngSubmit)="submit()">
        <span class="font-semibold text-base text-blue-darker" id="contact">
          {{ 'CLIENT.ABOUT_US.CONTACT.WRITE_TO_US' | translate }}
        </span>
        <div class="flex flex-column gap-4">
          <div class="flex flex-column md:flex-row align-items-stretch gap-5 md:gap-0">
            <div class="flex flex-column md:w-6 gap-4">
              <input type="text" formControlName="name" required pInputText placeholder="{{ 'CLIENT.ABOUT_US.CONTACT.INPUT.FIRSTNAME_LASTNAME' | translate }}" class="bg-blue-light border-blue-light-hover" />
              <div *ngIf="formGroup.get('name')?.touched && formGroup.get('name')?.invalid">
                <div class="p-message-error">
                  {{ 'CLIENT.ABOUT_US.CONTACT.INPUT.FIRSTNAME_LASTNAME.INCORRECT' | translate }}
                </div>
              </div>
              <input type="text" formControlName="email" required pInputText placeholder="{{ 'CLIENT.ABOUT_US.CONTACT.INPUT.EMAIL' | translate }}" class="bg-blue-light border-blue-light-hover" />
              <div *ngIf="formGroup.get('email')?.touched && formGroup.get('email')?.invalid">
                <div class="p-message-error">
                  {{ 'CLIENT.ABOUT_US.CONTACT.INPUT.EMAIL.INCORRECT' | translate }}
                </div>
              </div>
              <input type="text" formControlName="topic" required pInputText placeholder="{{ 'CLIENT.ABOUT_US.CONTACT.INPUT.SUBJECT' | translate }}" class="w-full bg-blue-light border-blue-light-hover" />
              <div *ngIf="formGroup.get('topic')?.touched && formGroup.get('topic')?.invalid">
                <div class="p-message-error">
                  {{ 'CLIENT.ABOUT_US.CONTACT.INPUT.SUBJECT.INCORRECT' | translate }}
                </div>
              </div>
            </div>
            <div class="flex flex-column justify-content-end align-content-center flex-grow-1 flex-wrap">
              <div id="checkBot"></div>
            </div>
          </div>
        </div>
        <textarea rows="15" formControlName="message" required pInputTextarea placeholder="{{ 'CLIENT.ABOUT_US.CONTACT.INPUT.CONTENT' | translate }}" class="bg-blue-light border-blue-light-hover">
        </textarea>
        <div *ngIf="formGroup.get('message')?.touched && formGroup.get('message')?.invalid">
          <div class="p-message-error">
            {{ 'CLIENT.ABOUT_US.CONTACT.INPUT.CONTENT.INCORRECT' | translate }}
          </div>
        </div>
        <p-button type="submit" styleClass="w-full justify-content-center font-semibold" [disabled]="formGroup.invalid">{{ 'CLIENT.ABOUT_US.CONTACT.BUTTON.SEND' | translate }}</p-button>
      </form>
    </div>
  </div>
</div>
