import { Component } from '@angular/core';

@Component({
  selector: 'app-solutions-dora-page',
  templateUrl: './solutions-dora-page.component.html',
  styleUrls: ['./solutions-dora-page.component.scss'],
})
export class SolutionsDoraPageComponent {

}
