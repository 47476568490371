import { Component } from '@angular/core';

@Component({
  selector: 'app-solutions-fds-page',
  templateUrl: './solutions-fds-page.component.html',
  styleUrls: ['./solutions-fds-page.component.scss'],
})
export class SolutionsFdsPageComponent {

}
