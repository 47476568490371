<header class="flex flex-wrap flex-row align-items-center justify-content-center topHeader">
  <div class="container py-3 md:py-4 px-4 xl:px-0 flex flex-row flex-grow-1 align-items-center justify-content-between align-self-stretch">
    <p-button icon="pi pi-bars" [rounded]="true" [text]="true" class="flex md:hidden" styleClass="text-black-black-100" (onClick)="toggleSidebar($event)"></p-button>
    <div class="flex justify-content-between align-items-center align-self-stretch gap-144px">
      <a routerLink="/" class="relative" data-testid="logo-button">
        <img src="assets/logo_poziom_linia.svg" alt="Logo - SourceMation" height="40" class="mr-0 hidden lg:flex" style="width: 200px;">
        <img src="assets/only_signet.png" alt="Logo - SourceMation" class="mr-0 flex lg:hidden">
      </a>
      <div class="align-items-center flex-grow-1 justify-content-between hidden md:flex w-full z-1 pl-8 lg:pl-0">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-row gap-6">
          <li>
            <a routerLink="/catalog" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer transition-colors transition-duration-150">
              {{ 'CLIENT.NAVIGATION.CATALOG' | translate }}
            </a>
          </li>
          <li>
            <div class="flex flex-row align-items-center justify-content-end gap-3 relative">
              <div class="hidden md:flex flex-row gap-2 align-items-center">
                <div class="flex flex-row gap-2 align-items-center cursor-pointer" (click)="solutionsToggle($event)">
                  <span class="text-black-black-100 font-semibold">
                    {{ 'CLIENT.NAVIGATION.SOLUTIONS' | translate }}
                  </span>
                </div>
                <div class="absolute p-3 bg-white border-round-lg flex flex-column gap-3 shadow-1 max-w-32rem" style="top: 45px; z-index: 10;" *ngIf="solutionsExpanded">
                  <a routerLink="/solutions/dora" pRipple class="flex flex-row gap-3 text-black-black-100 align-items-center no-underline white-space-nowrap hover:surface-100 border-round cursor-pointer transition-colors transition-duration-150">
                    {{ 'CLIENT.NAVIGATION.SOLUTIONS.DORA' | translate }}
                    <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
                  </a>
                  <a routerLink="/solutions/fds" pRipple class="flex flex-row gap-3 text-black-black-100 align-items-center no-underline white-space-nowrap hover:surface-100 border-round cursor-pointer transition-colors transition-duration-150">
                    {{ 'CLIENT.NAVIGATION.SOLUTIONS.FDS' | translate }}
                    <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
                  </a>
                  <a routerLink="/solutions/ezdrp" pRipple class="flex flex-row gap-3 text-black-black-100 align-items-center no-underline white-space-nowrap hover:surface-100 border-round cursor-pointer transition-colors transition-duration-150">
                    {{ 'CLIENT.NAVIGATION.SOLUTIONS.EZDRP' | translate }}
                    <i class="icon-16 icon-chevron-right bg-black-black-100 flex-shrink-0"></i>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a routerLink="/security" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer transition-colors transition-duration-150">
              {{ 'CLIENT.NAVIGATION.SECURITY' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/knowledge-base" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer transition-colors transition-duration-150">
              {{ 'CLIENT.NAVIGATION.KONWLEDGE_BASE' | translate }}
            </a>
          </li>
          <li>
            <a routerLink="/about-us" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer transition-colors transition-duration-150">
              {{ 'CLIENT.NAVIGATION.CONTACT' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex flex-row ml-6 align-items-center justify-content-end gap-3 relative">
      <div class="flex flex-row gap-2 align-items-center">
        <div class="flex flex-row gap-2 align-items-center cursor-pointer" (click)="langToggle($event)">
          <span class="text-black-black-100 font-semibold">
            {{ selectedLanguage.value }}
          </span>
          <div class="border-circle bg-blue-dark flag flag-{{selectedLanguage.code.toLowerCase()}} border-1 border-grey-normal"></div>
        </div>
        <div class="absolute p-3 bg-white border-round-lg flex flex-column flex-wrap gap-3 shadow-1 max-w-13rem" style="top: 45px; z-index: 10;" *ngIf="langExpanded">
          <div *ngFor="let lang of languages" class="flex flex-row gap-1 align-items-center cursor-pointer" (click)="changeLanguage(lang, $event)">
            <div class="flag flag-{{lang.flagCode ?? lang.code.toLowerCase()}} border-circle border-1 border-grey-normal"></div>
            <span>
              {{ lang.value }}
            </span>
          </div>
        </div>
      </div>
      <a routerLink="/signin" class="no-underline flex align-items-center text-blue-normal" *ngIf="!activeUser.id">
        <i class="icon-user icon-16 bg-blue-normal"></i>
        <span class="ml-2 font-semibold">{{ 'CLIENT.NAVIGATION.SIGN_IN' | translate }}</span>
      </a>
      <a routerLink="/profile" class="no-underline flex align-items-center text-blue-normal" *ngIf="activeUser.id">
        <i class="icon-user icon-16 bg-blue-normal"></i>
        <span class="ml-2 font-semibold">{{ 'CLIENT.NAVIGATION.USER' | translate }}</span>
      </a>
      <!-- <a href="#" class="no-underline flex align-items-center button-primary text-blue-light gap-2">
        <i class="icon-subscription icon-16 bg-white"></i>
        <span class="font-semibold">Subskrybuj</span>
      </a> -->
    </div>
  </div>
</header>

<div class="flex flex-row sticky top-0 align-items-center justify-content-center w-min-0" style="z-index: 5000;">
  <div class="container w-full w-min-0">
    <p-toast [life]="3500" position="bottom-right" [breakpoints]="{'2000px': {position: 'static', width: '100%', right: '0', left: '0'}}"></p-toast>
  </div>
</div>

<router-outlet></router-outlet>

<p-sidebar [(visible)]="sidebarVisible" position="left" styleClass="mainSidebar bg-special-background" [modal]="true" [autoZIndex]="true" [baseZIndex]="1000">
  <ng-template pTemplate="header"></ng-template>
  <ng-template pTemplate="closeicon"></ng-template>
  <div class="mt-8">
    <ul class="flex flex-column list-none gap-6">
      <li>
        <a routerLink="/catalog" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
          {{ 'CLIENT.NAVIGATION.CATALOG' | translate }}
        </a>
      </li>
      <li>
        <span pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer" (click)="solutionsToggle($event)">
          {{ 'CLIENT.NAVIGATION.SOLUTIONS' | translate }}
        </span>
      </li>
        <li *ngIf="solutionsExpanded">
          <a routerLink="/solutions/dora" pRipple class="ml-4 text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
            {{ 'CLIENT.NAVIGATION.SOLUTIONS.DORA' | translate }}
          </a>
        </li>
        <li *ngIf="solutionsExpanded">
          <a routerLink="/solutions/fds" pRipple class="ml-4 text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
            {{ 'CLIENT.NAVIGATION.SOLUTIONS.FDS' | translate }}
          </a>
        </li>
        <li *ngIf="solutionsExpanded">
          <a routerLink="/solutions/ezdrp" pRipple class="ml-4 text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
            {{ 'CLIENT.NAVIGATION.SOLUTIONS.EZDRP' | translate }}
          </a>
        </li>
      <li>
        <a routerLink="/security" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
          {{ 'CLIENT.NAVIGATION.SECURITY' | translate }}
        </a>
      </li>
      <li>
        <a routerLink="/knowledge-base" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
          {{ 'CLIENT.NAVIGATION.KONWLEDGE_BASE' | translate }}
        </a>
      </li>
      <li>
        <a routerLink="/about-us" pRipple class="text-black-black-100 align-items-center no-underline hover:surface-100 font-semibold border-round cursor-pointer">
          {{ 'CLIENT.NAVIGATION.CONTACT' | translate }}
        </a>
      </li>
    </ul>
  </div>
</p-sidebar>

<div class="mt-auto">
  <div class="bottom-0 flex align-items-center justify-content-center bg-blue-dark relative" *ngIf="shouldDisplayExpert()">
    <div class="absolute w-10rem right-0 z-0 bg-blue-normal-hover" style="transform: scaleY(-1) scaleX(-1); top: -23px; mask-image: url('/assets/decoration_pixels.svg'); mask-size: cover; mask-repeat: no-repeat; mask-composite: exclude; aspect-ratio: 55 / 63"></div>
    <div class="flex container w-full z-5 justify-content-center">
      <div class="flex flex-column align-items-start gap-4 md:gap-6 py-8 py-px-196 md:w-8">
        <p class="m-0 font-superbold text-white text-4xl md:text-7xl align-self-center md:align-self-start px-4 md:px-0">{{ 'CLIENT.CALL_TO_ACTION.TITLE' | translate }}</p>
        <p class="m-0 text-white text-base px-4 md:px-0">{{ 'CLIENT.CALL_TO_ACTION.DESCRIPTION' | translate }}</p>
        <div class="flex flex-column md:flex-row gap-3 justify-content-center align-items-center w-full">
          <a routerLink="/about-us" fragment="contact" class="cta px-4 py-3 no-underline border-round-lg flex-grow-1 justify-content-center text-center">
            <span class="font-bold">{{ 'CLIENT.CALL_TO_ACTION.BUTTON_CTA' | translate  }}</span>
          </a>
          <span class="text-white">{{ 'CLIENT.CALL_TO_ACTION.CONJUNCTION' | translate }}</span>
          <a routerLink="/catalog" class="bg-transparent border-1 px-4 py-3 no-underline border-round-lg flex-grow-1 text-center border-blue-light">
            <span class="font-bold text-white">{{ 'CLIENT.CALL_TO_ACTION.BUTTON_CATALOG' | translate }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="absolute w-10rem left-0 z-0 bg-blue-normal-hover" style="bottom: 160px; mask-image: url('/assets/decoration_pixels.svg'); mask-size: cover; mask-repeat: no-repeat; mask-composite: exclude; aspect-ratio: 55 / 63"></div>
  </div>

  <footer class="bottom-0 flex flex-column align-items-center justify-content-center border-top-1 border-blue-light-active overflow-hidden px-4 md:px-0" *ngIf="this.shouldDisplayFooterLinks()">
    <div class="md:pt-7 md:pb-5 pb-5 pt-2">
      <div class="md:pt-1 pt-5"></div>
    </div>
    <div class="container flex-grow-1 flex flex-row gap-2 flex-wrap md:flex-nowrap">
      <div class="flex flex-column gap-3">
        <img src="assets/logo_poziom_linia.svg" alt="Logo - SourceMation" class="mr-0 lg:mr-6" style="width: 336px;">
        <div class="flex flex-row pl-px-56 align-items-center">
          <span>by</span>
          <img src="assets/lp_logo.png" style="width: 135px;" alt="" class="pl-2">
        </div>
      </div>
      <div class="flex flex-column sm:flex-row gap-4 align-items-start w-full flex-wrap md:flex-nowrap">
        <div class="flex-grow-1 flex flex-column">
          <p class="m-0 font-bold text-grey-darker">{{ 'CLIENT.FOOTER.CATALOG_COLUMN.TITLE' | translate }}</p>
          <a href="https://www.linkedin.com/company/source-mation/" rel="noopener" target="_blank" class="flex flex-row align-items-center gap-1 no-underline text-sm text-grey-dark mt-4">
            <i class="pi pi-linkedin"></i>
            <span>
              LinkedIn
            </span>
          </a>
          <a href="https://www.facebook.com/sourcemation" rel="noopener" target="_blank" class="flex flex-row align-items-center gap-1 no-underline text-sm text-grey-dark mt-3">
            <i class="pi pi-facebook"></i>
            <span>
              Facebook
            </span>
          </a>
          <a href="https://www.youtube.com/channel/UCZ9YTpnsVKcfitSkN7MU5cQ" rel="noopener" target="_blank" class="flex flex-row align-items-center gap-1 no-underline text-sm text-grey-dark mt-3">
            <i class="pi pi-youtube"></i>
            <span>
              YouTube
            </span>
          </a>
        </div>
        <div class="flex flex-column flex-1 w-8 mt-4 md:mt-0 md:w-4">
          <p class="m-0 font-bold text-grey-darker">{{ 'CLIENT.FOOTER.SECOND_COLUMN.TITLE' | translate }}</p>
          <a routerLink="/knowledge-base" class="no-underline text-sm text-grey-dark mt-4">{{ 'CLIENT.FOOTER.SECOND_COLUMN.FAQ' | translate }}</a>
          <a routerLink="/about-us" fragment="contact" class="no-underline text-sm text-grey-dark mt-3">{{ 'CLIENT.FOOTER.SECOND_COLUMN.HELP' | translate }}</a>
          <a routerLink="/about-us" class="no-underline text-sm text-grey-dark mt-3">{{ 'CLIENT.FOOTER.SECOND_COLUMN.ABOUT_US' | translate }}</a>
        </div>
        <div class="flex flex-column flex-1 w-8 md:w-4">
          <a routerLink="/about-us" fragment="contact" class="no-underline font-bold text-grey-darker">{{ 'CLIENT.FOOTER.THIRD_COLUMN.TITLE' | translate }}</a>
          <a href="mailto:sourcemation@linuxpolska.com" class="no-underline text-sm text-grey-dark mt-4">sourcemation&#64;linuxpolska.com</a>
          <a href="tel:(+48) 22 213 95 71" class="no-underline text-sm text-grey-dark mt-3">(+48) 22 213 95 71</a>
          <div class="no-underline text-sm text-grey-dark mt-3 flex flex-column gap-1">
            <span class="text-nowrap">Al. Jerozolimskie 100,</span>
            <span>
              00-807 Warszawa, Polska
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="md:pt-7 md:pb-5 pb-5 pt-2">
    <div class="md:pt-1 pt-5"></div>
  </div>

  <footer class="bottom-0 flex align-items-center justify-content-center border-top-1 border-blue-light-hover">
    <div class="container flex-grow-1">
      <div class="flex flex-row align-items-center justify-content-center py-5 gap-3">
        <span class="font-normal text-grey-normal-active text-sm">© 2024 SourceMation powered by Linux Polska</span>
        <div>
          <a class="font-normal text-grey-dark text-sm no-underline" routerLink="/regulations">
            {{ 'CLIENT.FOOTER.REGULATIONS' | translate }}
            <i class="pi pi-external-link text-grey-dark ml-1 mr-1 text-xs"></i>
          </a>
        </div>
        <div>
          <a class="font-normal text-grey-dark text-sm no-underline" routerLink="/privacy-policy">
            {{ 'CLIENT.FOOTER.PRIVACY_POLICY' | translate }}
            <i class="pi pi-external-link text-grey-dark ml-1 mr-1 text-xs"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</div>

<div *ngIf="!localStorageService.getItem(regulationsStorageKey)" style="background-color: #DEEBFB" class="flex flex-row justify-content-center sticky bottom-0 z-5 transition-duration-200 shadow-4">
  <div class="container flex-grow-1 flex flex-row flex-wrap align-content-center justify-content-between py-4 px-4 md:px-0">
   <div class="flex flex-column md:flex-row align-items-center justify-content-center gap-3">
      <span class="font-normal text-grey-normal-active text-sm">
        {{ 'CLIENT.REGULATIONS.TEXT.FIRST_PART' | translate }}
        <a class="font-normal text-grey-dark text-sm no-underline" routerLink="/regulations">
          {{ 'CLIENT.REGULATIONS.TEXT.LINK.REGULATIONS' | translate }}
          <i class="pi pi-external-link text-grey-dark ml-1 mr-1 text-xs"></i>
        </a>
        {{ 'CLIENT.REGULATIONS.TEXT.SECOND_PART' | translate }}
        <a class="font-normal text-grey-dark text-sm no-underline" routerLink="/privacy-policy">
          {{ 'CLIENT.REGULATIONS.TEXT.LINK.PRIVACY_POLICY' | translate }}
          <i class="pi pi-external-link text-grey-dark ml-1 mr-1 text-xs"></i>
        </a>
        {{ 'CLIENT.REGULATIONS.TEXT.THIRD_PART' | translate }}
      </span>
      <p-button (onClick)="onAccept()" class="align-self-center" styleClass="cta">
        <span class="mx-2 font-bold">{{ 'CLIENT.REGULATIONS.BUTTON.ACCEPT' | translate }}</span>
        <i class="pi pi-check-circle font-bold"></i>
      </p-button>
   </div>
  </div>
</div>
